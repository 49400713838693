import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { UtilityService } from 'src/app/utility.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  registerForm: FormGroup = this.formBuilder.group({
    username: [
      null,
      [Validators.required, Validators.pattern(/^[^\s]+(\s+[^\s]+)*$/)],
    ],
    password: [
      null,
      [Validators.required, Validators.pattern(/^[^\s]+(\s+[^\s]+)*$/)],
    ],
  });
  router: Router;
  requiredUsername = false;
  requiredPassword = false;
  constructor(
    private formBuilder: FormBuilder,
    router: Router,
    private route: ActivatedRoute,
    public utilityService: UtilityService
  ) {
    this.router = router;
    const token = localStorage.getItem('AccessToken');
    if (token) {
      this.router.navigate(['home']);
    }
  }

  /**
   * (ngOnInit)
   * Description:
   * this function is called when angular initializes the component
   */
  ngOnInit() {
    setTimeout(() => {
      document.getElementById('uname').focus();
    }, 0);
  }

  /**
   * (keyDownFunction)
   * Description:
   * this function submits the form on enter key press
   */
  keyDownFunction(event: any) {
    if (event.keyCode == 13) {
      this.onSubmit();
    }
  }

  /**
   * (onSubmit)
   * Description:
   * this function is used to process the login attempt by the user
   */
  onSubmit() {
    let data = {
      AuthenticationRequest: {
        AuthenticationType: 'ISAS',
        authenticationMethod: 'Password',
        siteid: 1001,
        AuthenticationParameters: {
          username: this.registerForm.get('username').value,
          Password: this.registerForm.get('password').value,
        },
      },
    };
    this.utilityService.post(data, '/user/authentication', (res: any) => {
      if (res.AuthenticationResponse.ErrorCode === 0) {
        localStorage.setItem(
          'AccessToken',
          res.AuthenticationResponse.AccessToken
        );
        localStorage.setItem(
          'RefreshToken',
          res.AuthenticationResponse.RefreshToken
        );
        localStorage.setItem(
          'userName',
          res.AuthenticationResponse.UserDetails.Username
        );
        this.router.navigate(['em/home']);
        console.log(res);
      }
    });
  }
}
