import { Component, OnInit } from "@angular/core";
import { SimulatorService } from "../simulator.service";

@Component({
  selector: "app-cisco",
  templateUrl: "./cisco.component.html",
  styleUrls: ["./cisco.component.css"]
})
export class CiscoComponent implements OnInit {

  public selected = 'test';
  public Options = [
                    { name: 'rtls-test', value: 'test' },
                    { name: 'rtls-prod', value: 'prod' },
                    { name: 'rtls-perf', value: 'perf' }
                  ];
  settings : any[] = [];

  constructor(public simulatorService: SimulatorService) { }

  ngOnInit() {
    this.getCurrentIds();
  }

  beginSimulation(minId: string, maxId: string, change: string) {
    const min = parseInt(minId, 10);
    const max = parseInt(maxId, 10);
    this.simulatorService.setCiscoDeviceIds(min, max, change, this.selected).subscribe(res => {
      if(this.settings.length > 0) {
        let index = -1;
        for(let a in this.settings) {
          let setting = this.settings[a];
          if(this.selected.toUpperCase() === setting.env) index = parseInt(a);
          break;
        }
        if(index !== -1) this.settings.splice(index, 1);
      }
      this.settings.push({
        'env': this.selected.toUpperCase(),
        'start': min,
        'end': max,
        'change': change
      });
      alert(res.msg);
    });
  }

  resetIds() {
    this.simulatorService.resetCiscoDeviceIds(this.selected).subscribe(res => {
      if(this.settings.length > 0) {
        let index = -1;
        for(let a in this.settings) {
          let setting = this.settings[a];
          if(this.selected.toUpperCase() === setting.env) index = parseInt(a);
          break;
        }
        if(index !== -1) this.settings.splice(index, 1);
      }
      console.log(this.settings);
      alert(res.msg);
    });
  }

  getCurrentIds() {
    this.simulatorService.getCurrentCiscoIds().subscribe(res => {
      console.log(res);
      if(res.msg) this.settings = res.msg;
    });
  }
}
