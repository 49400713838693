import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-em',
  templateUrl: './em.component.html',
  styleUrls: ['./em.component.css']
})
export class EmComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  opened = false;

  onClick() {
    this.opened = true;
    setTimeout(() => {
      const el = document.getElementById('lsc_ifr');
      if (!el) return;
      const wd = (<HTMLIFrameElement>el).contentWindow;

      wd.postMessage(
        {
          AccessToken: localStorage.getItem('AccessToken'),
          RefreshToken: localStorage.getItem('RefreshToken'),
        },
        'https://lsc-test1.arachnomesh.com/'
      );
    }, 3000);
  }
}
