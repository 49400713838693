import { Component, OnInit } from "@angular/core";
import { SimulatorService } from "../simulator.service";


@Component({
  selector: "app-assetmapping",
  templateUrl: "./assetmapping.component.html",
  styleUrls: ["./assetmapping.component.css"]
})
export class AssetmappingComponent implements OnInit {
  asset;
  mappingEntity = [];
  finaldata=[];
  tempData = [];
  noOfMapping:number;
  constructor(public service: SimulatorService) { }

  ngOnInit() {
    this.service.getDataForAssetMapping().subscribe(res => {
      this.asset = res;

      //console.log(this.asset);

     
    });
  }

  beginSimulation(mapping,ip){
    alert(mapping + ip);
    this.noOfMapping=mapping;
    this.CreateMapping(ip);

  }

  CreateMapping(ip) {
    let i;
    for (let j = 1; j <=this.noOfMapping; j++) {
     this.tempData.push(
        this.asset[Math.floor(Math.random() * this.asset.length)]
      );
    }
    //console.log(this.tempData);
  
    
    for (i = 0; i <this.tempData.length; i++) {
    
      
      let firstEntity: string;
      let secondEntity: string;
      let firstEntityId: string;
      let secondEntityId: string;
      let temp: any = {};
      let firstIdNo=Math.floor(Math.random() * this.tempData.length);
      let secondIdNo=Math.floor(Math.random() * this.tempData.length);
      firstEntity = this.tempData[firstIdNo].AssetType;
      secondEntity = this.tempData[secondIdNo].AssetType;
     
        if (this.tempData[firstIdNo].AssetType == firstEntity) {
          firstEntityId = this.tempData[firstIdNo]._id;
        }else{

        }
        if (this.tempData[secondIdNo].AssetType == secondEntity) {
          secondEntityId = this.tempData[secondIdNo]._id;
        }
   

      if (firstEntity && secondEntity) {
        temp[firstEntity] = firstEntityId;
        temp[secondEntity] = secondEntityId;
      }
     
      if (Object.keys(temp).length > 1) {
        this.finaldata.push(temp);
       
      }
    }
    //console.log(this.finaldata);
    this.saveMapping(ip);
  }

  saveMapping(ip){
    this.service.saveAssetMapping(ip,this.finaldata,res=>{
      console.log(res);
      
    },err=>{
      console.log(err);
      
    })
  }


  
}
