import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { MednetComponent } from '../app/mednet/mednet.component';
import { AeroscoutComponent } from '../app/aeroscout/aeroscout.component';
import { HomeComponent } from './home/home.component';
import { AuthInterceptor } from 'src/auth-interceptor';
import { StafpatientComponent } from './stafpatient/stafpatient.component';
import { CiscoComponent } from './cisco/cisco.component';
import {  MatSelectModule } from '@angular/material';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { StaffComponent } from './staff/staff.component';
import { CentrakComponent } from './centrak/centrak.component';
import { AssetmappingComponent } from './assetmapping/assetmapping.component';
import { EmComponent } from './em/em.component';
import { LoginComponent } from './em/login/login.component';


@NgModule({
  declarations: [
    AppComponent,
    MednetComponent,
	AeroscoutComponent,
	HomeComponent,
	StafpatientComponent,
    HomeComponent,
    CiscoComponent,
    StaffComponent,
    CentrakComponent,
    AssetmappingComponent,
    EmComponent,
    LoginComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    HttpClientModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }
