import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../environments/environment';

const CORE_BACKEND_URL = environment.coreUrl;
const AEROSCOUT_BACKEND_URL = environment.aeroscoutUrl;

@Injectable({ providedIn: 'root' })
export class SimulatorService {

  constructor(private http: HttpClient) {}

  postDummyData(postData, ip: string) {
    const MEDNET_BACKEND_URL = 'http://' + ip + ':3002/status/';
    const contentType = new HttpHeaders({'Content-Type': 'application/json' });
    const options = { headers: contentType };
    // console.log(MEDNET_BACKEND_URL);
    return this.http.post<{
      message: string,
      status: {}
    }>(MEDNET_BACKEND_URL, postData, options);
  };
  postPatientData(postData, ip: string) {
    const Patient_BACKEND_URL = 'http://' + ip + ':3002/status/';
    const contentType = new HttpHeaders({'Content-Type': 'application/json' });
    const options = { headers: contentType };
    // console.log(MEDNET_BACKEND_URL);
    return this.http.post<{
      message: string,
      status: {}
    }>(Patient_BACKEND_URL, postData, options);
  };
 startPatientService(admitPatient:number,beds:number,dPatient:number,interval:number,ip:string,port:number){
  const Patient_BACKEND_URL = 'http://101.53.136.63:2410/patient/start';
  const contentType = new HttpHeaders({'Content-Type': 'application/json' });
  const options = { headers: contentType };
  let data = {admitPatient:admitPatient,beds:beds,dPatient:dPatient,interval:interval,ip:ip,port:port}
  // console.log(MEDNET_BACKEND_URL);
  return this.http.post<{
    message: string,
    status: {}
  }>(Patient_BACKEND_URL, data, options);
 };
 stopPatientService(){
   const Patient_BACKEND_URL ='http://101.53.136.63:2410/patient/stop'
   return this.http.get(Patient_BACKEND_URL);
 };
 getPatientServiceStatus(){
   const Patient_BACKEND_URL='http://101.53.136.63:2410/patient/status'
   return this.http.get(Patient_BACKEND_URL);
 }
  startAeroscoutService(postData) {
    return this.http.post<{
      message: string,
      status: {}
    }>(AEROSCOUT_BACKEND_URL, postData);
  }

  setCiscoDeviceIds(min, max, change, env) {
    const postData = {
      deviceFrom: min,
      deviceTo: max,
      changePercent: change
    };
    return this.http.post<{
      msg: string
    }>(`${environment.ciscoSimulatorServerUrl}setIds/` + env, postData);
  }

  resetCiscoDeviceIds(env) {
    return this.http.get<{
      msg: string
    }>(`${environment.ciscoSimulatorServerUrl}resetIds/` + env)
  }

  getCurrentCiscoIds() {
    return this.http.get<{
      msg: any
    }>(`${environment.ciscoSimulatorServerUrl}status`)
  }

  startCentrakService(env, interv) {
    const postData = {
      environment: env,
      interval: interv
    };
    return this.http.post<{msg: string}>(`${environment.centrakSimulatorServerUrl}centrak/start`, postData);
  }

  stopCentrakService(env) {
    return this.http.get<{msg: string, status: number}>(`${environment.centrakSimulatorServerUrl}centrak/stop/` + env);
  }

  getCentrakServiceStatus() {
    return this.http.get<{msg: string}>(`${environment.centrakSimulatorServerUrl}centrak/status/`);
  }

  startMedNetService(env: string, devices: number, time: number) {
    const postData = {
      environment: env,
      maxDevices: devices,
      interval: time
    };
    return this.http.post<{msg: string}>(`${environment.mednetSimulatorServerUrl}streaming/startStreaming`, postData);
  }

  stopMednetService(env) {
    return this.http.get<{msg: string, status: number}>(`${environment.mednetSimulatorServerUrl}streaming/stopStreaming/` + env);
  }

  getMednetServiceStatus() {
    return this.http.get<{msg: any}>(`${environment.mednetSimulatorServerUrl}streaming/getStreamingStatus`);
  }

  startStaffService(perChange: number, interval: number, ip: string) {
    const STAFF_BACKEND_URL = "http://localhost:2410/staff/start";
    const contentType = new HttpHeaders({ "Content-Type": "application/json" });
    const options = { headers: contentType };
    let data = { perChange: perChange, interval: interval, ip: ip };
    return this.http.post<{
      message: string;
      status: {};
    }>(STAFF_BACKEND_URL, data, options);
  }

  stopStaffService() {
    const STAFF_BACKEND_URL = "http://localhost:2410/staff/stop";
    return this.http.get(STAFF_BACKEND_URL);
  }

  getStaffServiceStatus() {
    const STAFF_BACKEND_URL = "http://localhost:2410/staff/status";
    return this.http.get(STAFF_BACKEND_URL);
  }

  getAllAssetIds(ip: string) {
  return this.http.get<[{AssetId: string}]>('http://' + ip + ':4000/api/assetIds');
  }

  getDataForAssetMapping(){
    const core_url="http://localhost:4000/api/asset.getAsset"
    return this.http.get(core_url);
  }

  saveAssetMapping(ip,data,success,failure){
    const core_url='http://'+ip+':4000/api/assets.mapAssets';
      this.http.post(core_url, data).subscribe((res) => {
        success(res)
      }, (err) => {
        failure(err);
      }
      );

  }

}
