import { Component, OnInit } from '@angular/core';
import { SimulatorService } from '../simulator.service';

/**
 * @title Basic Inputs
 */
@Component({
  selector: 'app-simulator-mednet',
  styleUrls: ['mednet.component.css'],
  templateUrl: 'mednet.component.html',
})
export class MednetComponent implements OnInit {

  public wifi = ['BAR_ONE', 'BAR_TWO', 'BAR_THREE', 'BAR_FOUR', 'BAR_FIVE'];
  public battery = ['LEVEL_1', 'LEVEL_2', 'LEVEL_3', 'LEVEL_4'];
  public deviceType = 'Equipment';
  public status = ['comm-status-offline', 'comm-status-online'];
  public infusing = ['pump-status-infusing', 'pump-status--not-infusing'];
  public devices = 0;
  public timeElapsed: number = 0;
  public AssetIds = [];
  public isLoading = false;
  public auto;
  public timeElapsedCal;
  public selected = 'test';
  public Options = [
    { name: 'rtls-test', value: 'test' },
    { name: 'rtls-prod', value: 'prod' },
    { name: 'rtls-perf', value: 'perf' }
  ];
  settings: any[] = [];

  public data = {
    DeviceId: '151',
    DeviceType: 'Equiment',
    Fields: {
      DeviceStatus: 'comm-status-offline',
      WifiStrength: { Strength: 'BAR_FOUR', MaximumBars: '5' },
      BatteryLevel: { Level: 'LEVEL_1', MaximumLevels: '4' },
      VolumeInfused: { Volume: '33.4', Unit: 'mL' },
      InfusionStatus: 'pump-status-infusing'
    }
  };

  constructor(private simulatorService: SimulatorService) { }

  ngOnInit() {
    this.getStreamingStatus();
  }

  beginSimulation(devices: number, interval: number) {
    if (!devices || !interval) {
      alert('Please fill all the required field');
      return;
    }
    if (devices <= 0 || interval <= 0) {
      alert('Please fill correct data');
      return;
    }
    this.simulatorService.startMedNetService(this.selected, devices, interval).subscribe(res => {
      if (this.settings.length > 0) {
        let index = -1;
        for (let a in this.settings) {
          let setting = this.settings[a];
          let selected = this.selected.toUpperCase();
          console.log(selected);
          if (this.selected.toUpperCase() === setting.env) {
            index = parseInt(a);
          }
          break;
        }
        console.log(index);
        if (index !== -1) this.settings.splice(index, 1);
      }
      this.settings.push({
        'env': this.selected.toUpperCase(),
        'deviceCount': devices,
        'interval': interval
      });
      console.log(this.settings);
      alert(res.msg);
    });
  }

  stopSimulation() {
    this.simulatorService.stopMednetService(this.selected).subscribe(res => {
      if (this.settings.length > 0) {
        let index = -1;
        for (let a in this.settings) {
          let setting = this.settings[a];
          console.log(setting.env, this.selected);
          if (this.selected.toUpperCase() === setting.env) index = parseInt(a);
          break;
        }
        console.log(index);
        if (index !== -1) this.settings.splice(index, 1);
      }
      console.log(this.settings);
      alert(res.msg);
    });
  }

  getStreamingStatus() {
    this.simulatorService.getMednetServiceStatus().subscribe(res => {
      console.log(res);
      if (res.msg) this.settings = res.msg;
    });
  }

}
