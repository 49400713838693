import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

const BACKEND_URL = 'https://rtls-simulator.arachnomesh.com/api/v1';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  constructor(private http: HttpClient) {}

  post(data: any, url: any, callback: any) {
    const serverUrl = BACKEND_URL + url;
    this.http.post(serverUrl, data).subscribe(
      (res) => {
        callback(res);
      },
      (err) => {
        callback(err);
      }
    );
  }
}
