import { Component, OnInit } from "@angular/core";
import { SimulatorService } from "../simulator.service";
import { environment } from "../../environments/environment";
import { HttpClient } from "@angular/common/http";
const BACKEND_URL = environment.locationUrl;

/**
 * @title Basic Inputs
 */
@Component({
  selector: "app-aeroscout",
  styleUrls: ["./aeroscout.component.css"],
  templateUrl: "./aeroscout.component.html"
})
export class AeroscoutComponent implements OnInit {
  constructor(
    private simulatorService: SimulatorService,
    private http: HttpClient
  ) {}

  public mapObj: any;
  public selected:string;
  public Options=["rtls","rtls-test","rtls-performance"];

  ngOnInit() {
    this.getMap();
  }

  beginSimulation(start: number, end: number, change: number) {
    const data = {
      start: start,
      end: end,
      host: this.selected,
      percentChange: change
    };
    this.simulatorService.startAeroscoutService(data).subscribe(response => {
       alert('Details Saved Successfully!');
    });
  }

  getMap() {
    const serverUrl = BACKEND_URL + "location.getAllMaps";
    this.http.get(serverUrl).subscribe(
      res => {
        this.mapObj = res;
      },
      err => {
        console.log(err);
      }
    );
  }
}
