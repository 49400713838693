import { Component, OnInit } from '@angular/core';
import {SimulatorService} from '../simulator.service';

@Component({
  selector: 'app-simulator-stafpatient',
  templateUrl: './stafpatient.component.html',
  styleUrls: ['./stafpatient.component.css']
})
export class StafpatientComponent implements OnInit {

  public timeElapsed: number = 0;
  public isLoading = false;
  public timeElapsedCal;
  public auto =0;
  

  constructor(private simulatorService: SimulatorService) {
    this.simulatorService.getPatientServiceStatus().subscribe(response =>{
      console.log(response);
      if(response["status"]===1){
        this.isLoading= true;
        this.timeElapsed = response["timeElapsed"];
        this.timeElapsedCal = setInterval(()=>{
          this.timeElapsed = this.timeElapsed +1;
        },1000);
      }else if(response["status"]===0){
        this.isLoading = false;
      }
    });
   }

  ngOnInit() {
  
  }


beginSimulation(admitPatient:number,interval: number, ip: string, beds: number,dPatient:number,port:number) {
  if (!admitPatient || !interval || !ip || !beds || !dPatient || !port) {
    alert('Please enter all the fields!');
    return;
  }
  this.isLoading = true;
    if (ip.includes(',')) {
      const ipList: any[] = ip.split(',');
    }
    this.simulatorService
    .startPatientService(admitPatient,beds,dPatient,interval,ip,port)
    .subscribe(response=>{
      console.log(response);
    });
    this.timeElapsedCal = setInterval(()=>{
      this.timeElapsed = this.timeElapsed + 1;
    },1000);

  }




stopSimulation() {
  this.isLoading = false;
  this.timeElapsed = 0;
  clearInterval(this.timeElapsedCal);
  this.simulatorService.stopPatientService().subscribe(response=>{
    console.log(response);
  });
  }

}

