import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    // tslint:disable-next-line: max-line-length//
    const authToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImRlbW9AZ21haWwuY29tIiwibmFtZSI6IlRlc3QiLCJpYXQiOjE1NzcwOTY4OTIsImV4cCI6MTU3NzEwMDQ5Mn0.CmMS8TaX7BM89SdNRfyvK19YwPu1B9Bc_kKPzGE4bMY';
    // to manipulate a request, first clone it. otherwise runs into error
    const authRequest = req.clone({
      headers: req.headers.set('Authorization', 'Bearer ' + authToken)
    });
    return next.handle(authRequest);
  }
}
