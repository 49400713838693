import { Component, OnInit } from "@angular/core";
import { SimulatorService } from "../simulator.service";

/**
 * @title Basic Inputs
 */
@Component({
  selector: "app-simulator-staff",
  styleUrls: ["staff.component.css"],
  templateUrl: "staff.component.html"
})
export class StaffComponent implements OnInit {
  public timeElapsed: number = 0;
  public isLoading = false;
  public auto;
  public timeElapsedCal;
  // public runs = 0;

  constructor(private simulatorService: SimulatorService) {
    this.simulatorService.getStaffServiceStatus().subscribe(response => {
      console.log(response);
      if (response["status"] === 1) {
        this.isLoading = true;
        this.timeElapsed = response["timeElapsed"];
         this.timeElapsedCal = setInterval(() => {
           this.timeElapsed = this.timeElapsed + 1;
         }, 1000);
      } else if (response["status"] === 0) {
        this.isLoading = false;
      }
    });
  }

  ngOnInit() {
    /* this.simulatorService.getAllAssetIds().subscribe(resp => {
          this.AssetIds = resp;
          console.log(this.AssetIds);
        }); */
  }

  beginSimulation(perChange: number, interval: number, ip: string) {
    if (!perChange || !interval || !ip) {
      alert("Please enter all the fields!");
      return;
    }
    this.isLoading = true;
    if (ip.includes(",")) {
      const ipList: any[] = ip.split(",");
      //   for (const k in ipList) {
      //     this.postData(perChange,interval,ipList[k]);
    }
    // } else {
    this.simulatorService
      .startStaffService(perChange, interval, ip)
      .subscribe(response => {
        console.log(response);
      });
    //}
    this.timeElapsedCal = setInterval(() => {
      this.timeElapsed = this.timeElapsed + 1;
    }, 1000);
  }

  stopSimulation() {
    this.isLoading = false;
    this.timeElapsed = 0;
    clearInterval(this.timeElapsedCal);
    this.simulatorService.stopStaffService().subscribe(response => {
      console.log(response);
    });
  }
}
