import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MednetComponent } from '../app/mednet/mednet.component';
import { AeroscoutComponent } from '../app/aeroscout/aeroscout.component';
import { HomeComponent } from './home/home.component';
import { StafpatientComponent } from '../app/stafpatient/stafpatient.component';
import { StaffComponent } from "./staff/staff.component";
import { CiscoComponent } from "./cisco/cisco.component";
import { CentrakComponent } from "./centrak/centrak.component";
import { AssetmappingComponent } from './assetmapping/assetmapping.component';
import { EmComponent } from './em/em.component';
import { LoginComponent } from './em/login/login.component';

const routes: Routes = [
{
  path: '',
  component: HomeComponent
},
{
  path: 'mednet',
  component: MednetComponent
},
{
  path: 'aeroscout',
  component: AeroscoutComponent
},
{
  path: 'staffpatient',
  component: StafpatientComponent

},
{
    path: "cisco",
    component: CiscoComponent
  },
  {
    path: "staff",
    component: StaffComponent
  },
  {
    path: "centrak",
    component: CentrakComponent
  },
  {
    path: "mapping",
    component: AssetmappingComponent
  },
  {
    path: "em/home",
    component: EmComponent
  },
  {
    path: "em/login",
    component: LoginComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
