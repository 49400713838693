import { Component, OnInit } from '@angular/core';
import { SimulatorService } from '../simulator.service';


@Component({
  selector: 'app-centrak',
  templateUrl: './centrak.component.html',
  styleUrls: ['./centrak.component.css']
})
export class CentrakComponent implements OnInit {

  public timeElapsed: number = 0;
  public isLoading = false;
  public timeElapsedCal;
  public auto =0;
  public selected = 'test';
  public Options = [{name: 'rtls-prod', value: 'prod'},
                    {name: 'rtls-test', value: 'test'},
                    {name: 'rtls-performance', value: 'perf'}];

  constructor(public simulatorService: SimulatorService) {
    //this.getStreamingStatus();
  }

  ngOnInit() {
  }

  beginSimulation(interval: number) {
   /*  // tslint:disable-next-line: radix
    const min = parseInt(minId.substring(3, minId.length));
    // tslint:disable-next-line: radix
    const max = parseInt(maxId.substring(3, maxId.length));
 */
    this.simulatorService.startCentrakService(this.selected, interval).subscribe(res => {
      alert(res.msg);
    });
  }

  stopSimulation() {
    this.simulatorService.stopCentrakService(this.selected).subscribe(res => {
      alert(res.msg);
    });
  }

  getStreamingStatus() {
    this.simulatorService.getCentrakServiceStatus().subscribe(res => {
      alert(res.msg);
    });
  }

}
